import React from "react"
import { Helmet } from "react-helmet"
import { DivedownBox } from "../components/divedowns"
import { DetailedAttributeList } from "../components/attribute"
import Layout from "../components/layout"
import Ad from "../components/ad"

import { graphql } from "gatsby"

import HeaderPic from "../components/headerpic"
import headerPic from "../../static/headerpics/herkunft.png"


export default function Herkunft({ data }) {
  const origins = data.pagesJson.divedowns
    .filter((divedown) => divedown.attribute.category === 'Herkunft')
    .sort((d1, d2) => d1.attribute.rank - d2.attribute.rank)

  return (
    <Layout>
      <Helmet>
        <title>Vornamen nach Herkunft</title>
        <meta name="description"
          content="16151 Vornamen aus über 70 Ländern." />
        <meta name="keywords"
          content="vornamen, herkunft, land" />
        <link rel="canonical" href="/nach-herkunft" />
      </Helmet>
      <div className="paper">
        <HeaderPic imagePath={headerPic} />
        <h1>Vornamen nach Herkunft</h1>
        <p>Ein Name ist so viel mehr als die Verbindung einiger Buchstaben.
        Er kann zum Beispiel auf die Herkunft Ihrer Familie hinweisen,
        die Erinnerung an erlebnisreiche Reisen in ferne Länder wachhalten
        oder Ihre Verbundenheit zu Ihrer Heimat ausdrücken.
        Reisen Sie mit der swissmom-Vornamenssuche um die Welt und
        finden Sie wohlklingende Babynamen aus verschiedenen Sprachregionen.
          </p>
      </div>
      <div className="paper">
        <h2>Top 3 Herkünfte</h2>
        <DetailedAttributeList
          attributes={origins.slice(0, 3).map(d => d.attribute)}
          show_category={false} />
      </div>
      <div className="paper">
        <h2>Schweizer Namen</h2>
        <DetailedAttributeList
          attributes={origins
            .filter(d => ['schweizerisch', 'rätoromanisch', 'deutsch', 'französisch', 'italienisch'].includes(d.attribute.id))
            .map(d => d.attribute)
          }
          show_category={false} />
      </div>
      <Ad id="adnz_maxiboard_1" />
      <div className="paper">
        <h2>Alle Herkünfte</h2>
        <DivedownBox
          divedowns={origins.slice(4)}
          initial_limit={100}
        />
      </div>
      <Ad id="adnz_maxiboard_2" />
    </Layout>
  )
}

export const query = graphql`
  query {
    pagesJson(url: {eq: ""}) {
      divedowns {
        attribute {
          imagePath
          associated_page_url
          category
          id
          latest_births
          popularity
          rank
          title
          trend
          births {
            births
            years
          }
        }
        number_of_names
        subpage_url
      }
    }
  }
  `